@import "../../styles/variables.scss";
@import "../../styles/breakpoints.scss";

.contactButton {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  color: $burnham;
  background-color: $selective-yellow;
  border: none;
  border-radius: 50%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 12;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  position: fixed;
  bottom: 6rem;
  right: 1rem;
  background: $white-smoke;
  border-radius: 1rem;
  z-index: 11;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  @include slideUp(0.7s, ease-in-out);
  .modalTitleDiv {
    background-color: $selective-yellow;
    padding: 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    .modalTitle {
      color: $black-pearl;
      @include mobile {
        font-size: 1.5rem;
      }
    }
    .modalDescription {
      color: $burnham;
      font-size: 0.8rem;
    }
  }
  .form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    .formGroup {
      display: flex;
      flex-direction: column;
      .label {
        font-size: 0.8rem;
        color: $dartmouth-green;
        margin-bottom: 0.3rem;
      }
      .input {
        padding: 0.6rem;
        border: 1px solid $dartmouth-green;
        outline: none;
        border-radius: 0.5rem;
        font-size: 0.8rem;
        resize: none;
        background-color: $white-smoke;
        color: $dartmouth-green;
        margin-bottom: 1rem;
        &::placeholder {
          font-family: $font;
          color: $opal;
          font-weight: 400;
        }
      }
      .errorInput {
        padding: 0.6rem;
        border: 1px solid $dartmouth-green;
        outline: none;
        border-radius: 0.5rem;
        font-size: 0.8rem;
        resize: none;
        background-color: $white-smoke;
        color: $dartmouth-green;
        margin-bottom: 0rem;
        &::placeholder {
          font-family: $font;
          color: $opal;
          font-weight: 400;
        }
      }
      .errorText {
        color: $error-red;
        font-size: 0.6rem;
        font-weight: 500;
        margin: 0.2rem 0rem 0.8rem 0rem;
      }
    }
    .submitButton {
      background-color: $dartmouth-green;
      color: $white-smoke;
      padding: 0.8rem 1rem;
      border: none;
      border-radius: 0.5rem;
      cursor: pointer;
      font-family: $font;
      font-weight: 500;
      font-size: 0.8rem;
    }
    .result {
      text-align: center;
      color: $dartmouth-green;
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
}
