@import "../../styles/variables.scss";
@import "../../styles/breakpoints.scss";

.headerMainDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  width: 55rem;
  height: 3.3rem;
  margin: 1rem;
  background-color: $burnham;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  border: 2px solid $dartmouth-green;
}

.logo a {
  color: $tealish-green;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1rem;
}

.nav {
  display: flex;
  gap: 2rem;
  margin-right: 1rem;
  a {
    color: $white-smoke;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  .activeLink {
    color: $greenish-teal;
  }
  @include mobile {
    display: none;
  }
}

.hamburger {
  display: none;
  @include mobile {
    display: block;
    cursor: pointer;
    color: $tealish-green;
    margin-right: 0.7rem;
    z-index: 10;
  }
}

.activeMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $burnham;
  z-index: 10;
  a {
    font-size: 1.3rem;
    text-decoration: none;
    text-align: center;
  }
}
