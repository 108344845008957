@import "../../styles/variables.scss";
@import "../../styles/breakpoints.scss";

.loaderMainDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  @include fadeIn(3s, ease-in);
}

.loader {
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid $tealish-green;
  border-radius: 50%;
  opacity: 0;
  animation: ripple 1.5s infinite ease-in-out;
}

.circle:nth-child(2) {
  animation-delay: 0.3s;
}

.circle:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes ripple {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}
