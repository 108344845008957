// global color variable
$black-pearl: #021a1a;
$burnham: #042222;
$dartmouth-green: #03624c;
$tealish-green: #00df82;
$greenish-teal: #2cc295;
$white-smoke: #f1f7f7;
$opal: #a9cbc3;
$selective-yellow: #ffbb00;
$error-red: #ff4e50;
$error-red-dark: #e43b40;

// global animation variable
@mixin fadeIn($duration: 1s, $timing-function: ease-in) {
  animation: fadeIn $duration $timing-function forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin slideUp($duration: 0.5s, $timing-function: ease-out) {
  animation: slideUp $duration $timing-function forwards;
  @keyframes slideUp {
    0% {
      transform: translateY(2%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

// global font variable
$font: "Rubik", serif;

// global utility variable
$max-lines-title: 1;
$max-lines-description: 3;
$max-lines-projectpage-description: 2;
