@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import "./styles//variables.scss";
@import "./styles//breakpoints.scss";

body {
  margin: 0;
  padding: 0;
  background-color: $black-pearl;
  font-family: "Rubik", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

h1 {
  font-size: 3.3rem;
  font-weight: 600;
  color: $white-smoke;
  margin: 0px;
  @include mobile {
    font-size: 1.875rem;
  }
}

h2 {
  font-size: 2.8rem;
  font-weight: 600;
  color: $white-smoke;
  margin: 0px;
  @include mobile {
    font-size: 1.625rem;
  }
}

h3 {
  font-size: 2.3rem;
  font-weight: 600;
  color: $white-smoke;
  margin: 0px;
  @include mobile {
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 1.8rem;
  font-weight: 600;
  color: $white-smoke;
  margin: 0px;
  @include mobile {
    font-size: 1.25rem;
  }
}

h5 {
  font-size: 1.5rem;
  font-weight: 600;
  color: $white-smoke;
  margin: 0px;
  @include mobile {
    font-size: 1rem;
  }
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  color: $white-smoke;
  margin: 0px;
  @include mobile {
    font-size: 0.875rem;
  }
}

/* Prevent scroll for header on mobile devices */
.noScroll {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  width: 100%;
}

/* Custom selection styling */
::selection {
  background-color: $selective-yellow;
  color: $burnham;
}

/* For Webkit-based browsers (Safari, Chrome) */
::-webkit-selection {
  background-color: $selective-yellow;
  color: $burnham;
}
