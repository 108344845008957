// Responsive Breakpoints for different devices
$breakpoints: (
  small-device-min: 0px,
  small-device-max: 200px,
  normal-device-min: 200px,
  normal-device-max: 320px,
  large-device-min: 320px,
  large-device-max: 576px,
  small-tablet-min: 576px,
  small-tablet-max: 768px,
  normal-tablet-min: 768px,
  normal-tablet-max: 992px,
  large-tablet-min: 992px,
  large-tablet-max: 1200px,
  small-desktop-min: 1200px,
  small-desktop-max: 1400px,
  normal-desktop-min: 1400px,
  normal-desktop-max: 1600px,
  large-desktop-min: 1600px,
  large-desktop-max: 1920px,
  extra-large-desktop-min: 1920px,
  extra-large-desktop-max: 2560px,
  //   Specific Devices
  iphone-se-min: 375px,
  iphone-se-max: 667px,
  iphone-14ProMax-min: 430px,
  iphone-14ProMax-max: 932px,
  ipad-mini-min: 768px,
  ipad-mini-max: 1024px,
  ipad-air-min: 820px,
  ipad-air-max: 1180px,
  ipad-pro-min: 1024px,
  ipad-pro-max: 1366px,
  // Global Devices
  mobile-min: 0px,
  mobile-max: 576px,
);

// For all Small Mobile devices
@mixin small-device {
  @media (min-width: map-get($breakpoints, small-device-min)) and (max-width: map-get($breakpoints, small-device-max)) {
    @content;
  }
}

// For all Normal Mobile devices
@mixin normal-device {
  @media (min-width: map-get($breakpoints, normal-device-min)) and (max-width: map-get($breakpoints, normal-device-max)) {
    @content;
  }
}

// For all Large Mobile devices
@mixin large-device {
  @media (min-width: map-get($breakpoints, large-device-min)) and (max-width: map-get($breakpoints, large-device-max)) {
    @content;
  }
}

// For all Small Tablet devices
@mixin small-tablet {
  @media (min-width: map-get($breakpoints, small-tablet-min)) and (max-width: map-get($breakpoints, small-tablet-max)) {
    @content;
  }
}

// For all Normal Tablet devices
@mixin normal-tablet {
  @media (min-width: map-get($breakpoints, normal-tablet-min)) and (max-width: map-get($breakpoints, normal-tablet-max)) {
    @content;
  }
}

// For all Large Tablet devices
@mixin large-tablet {
  @media (min-width: map-get($breakpoints, large-tablet-min)) and (max-width: map-get($breakpoints, large-tablet-max)) {
    @content;
  }
}

// For all Small Desktop devices
@mixin small-desktop {
  @media (min-width: map-get($breakpoints, small-desktop-min)) and (max-width: map-get($breakpoints, small-desktop-max)) {
    @content;
  }
}

// For all Normal Desktop devices
@mixin normal-desktop {
  @media (min-width: map-get($breakpoints, normal-desktop-min)) and (max-width: map-get($breakpoints, normal-desktop-max)) {
    @content;
  }
}

// For all Large Desktop devices
@mixin large-desktop {
  @media (min-width: map-get($breakpoints, large-desktop-min)) and (max-width: map-get($breakpoints, large-desktop-max)) {
    @content;
  }
}

// For all Extra Large Desktop devices
@mixin extra-large-desktop {
  @media (min-width: map-get($breakpoints, extra-large-desktop-min)) and (max-width: map-get($breakpoints, extra-large-desktop-max)) {
    @content;
  }
}

// For all iPhone SE devices
@mixin iphone-se {
  @media (width: map-get($breakpoints, iphone-se-min)) and (height: map-get($breakpoints, iphone-se-max)) {
    @content;
  }
}

// For all iPhone 14 Pro Max devices
@mixin iphone-14ProMax {
  @media (width: map-get($breakpoints, iphone-14ProMax-min)) and (height: map-get($breakpoints, iphone-14ProMax-max)) {
    @content;
  }
}

// For all iPad Mini devices
@mixin ipad-mini {
  @media (width: map-get($breakpoints, ipad-mini-min)) and (height: map-get($breakpoints, ipad-mini-max)) {
    @content;
  }
}

// For all iPad Air devices
@mixin ipad-air {
  @media (width: map-get($breakpoints, ipad-air-min)) and (height: map-get($breakpoints, ipad-air-max)) {
    @content;
  }
}

// For all iPad Pro devices
@mixin ipad-pro {
  @media (min-width: map-get($breakpoints, ipad-pro-min)) and (max-width: map-get($breakpoints, ipad-pro-max)) {
    @content;
  }
}

// For all Mobile devices
@mixin mobile {
  @media (min-width: map-get($breakpoints, mobile-min)) and (max-width: map-get($breakpoints, mobile-max)) {
    @content;
  }
}
