@import "../../styles/variables.scss";
@import "../../styles/breakpoints.scss";

.shootingStars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: -1;
  @include fadeIn(3s, ease-in);
  .shootingStar {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 20px solid $greenish-teal;
    opacity: 0;
    animation: shoot 2s linear forwards, fadeInOut 2s linear forwards;
    &:nth-child(odd) {
      animation-delay: 0.5s;
    }
  }
}

@keyframes shoot {
  0% {
    transform: translateY(0) translateX(0) rotate(45deg);
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) translateX(-100vw) rotate(45deg);
    opacity: 0;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
