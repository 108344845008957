@import "../../styles/variables.scss";
@import "../../styles/breakpoints.scss";

.footerMainDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include fadeIn(3s, ease-in);
}

.footer {
  width: 55rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.footerText {
  .logoText {
    text-align: center;
    color: $opal;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .allRights {
    text-align: center;
    color: $opal;
    font-size: 1rem;
  }
  .madeWith {
    text-align: center;
    color: $opal;
    font-size: 1rem;
  }
}
